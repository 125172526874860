import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type {
    TrackFinanceadsEventInput,
    trackFinanceadsEventMutationResponse,
} from '__generated__/trackFinanceadsEventMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

const trackFinanceadsEventMutation =
    (relayEnv: Environment) => (input: TrackFinanceadsEventInput) => {
        return createMutationPromise<trackFinanceadsEventMutationResponse>(relayEnv)({
            mutation: graphql`
                mutation trackFinanceadsEventMutation($input: TrackFinanceadsEventInput!) {
                    trackFinanceadsEvent(input: $input) {
                        clientMutationId
                    }
                }
            `,
            variables: { input },
        })
    }

export default trackFinanceadsEventMutation
