import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type {
    TrackImpactEventInput,
    trackImpactEventMutationResponse,
} from '__generated__/trackImpactEventMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

const trackImpactEventMutation = (relayEnv: Environment) => (input: TrackImpactEventInput) => {
    return createMutationPromise<trackImpactEventMutationResponse>(relayEnv)({
        mutation: graphql`
            mutation trackImpactEventMutation($input: TrackImpactEventInput!) {
                trackImpactEvent(input: $input) {
                    clientMutationId
                }
            }
        `,
        variables: { input },
    })
}

export default trackImpactEventMutation
