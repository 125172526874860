import { useCallback, useEffect, useMemo, useState } from 'react'

import constate from 'constate'
import { useRouter } from 'next/router'

import { FINANCEADS_ID_PARAM } from './consts'
import type { FinanceadsTrackingFn } from './types'

import { DAY_IN_SECONDS, useCookies } from 'lib/useCookies'
import trackFinanceadsEventMutation from 'relay/mutations/trackFinanceadsEvent'

/**
 * This hook is used to be able to track events in financeAds.
 * It should be used in components where we want to track events, e.g. in the signup flow.
 *
 * Use it as follows:
 * - get the client: financeadsClient = useFinanceadsContext()
 * - call the track method (or pass it as a prop to child components), e.g. financeadsClient.track(environment, eventName, data)
 */

const useFinanceadsTracking = () => {
    // NOTE All methods which contact the API MUST be gated
    // with a check on `isAuthorized`.
    const router = useRouter()
    const { query: params = {} } = router
    const { cookies, setCookie } = useCookies()
    const [isAuthorized, setIsAuthorized] = useState(false)

    const financeadsId = useMemo(() => {
        if (cookies && cookies[FINANCEADS_ID_PARAM]) {
            return cookies[FINANCEADS_ID_PARAM]
        }
        return undefined
    }, [cookies])

    useEffect(() => {
        if (!isAuthorized) return

        // If the tracking client is authorised and we don't have an s_id in the
        // cookies already, check the query params for an s_id and set the cookie
        if (isAuthorized && !financeadsId) {
            Object.entries(params).forEach(([key, val]) => {
                if (key === FINANCEADS_ID_PARAM && typeof val === 'string') {
                    const cookieKeyName = FINANCEADS_ID_PARAM

                    const options = {
                        maxAge: 30 * DAY_IN_SECONDS, // 30 days
                        path: '/',
                    }

                    const decodedVal = decodeURIComponent(val)

                    setCookie(cookieKeyName, decodedVal, options)
                }
            })
        }
    }, [isAuthorized, financeadsId])

    /**
     * This method is called when the user has given consent to tracking, which should only happen in CookieModal.
     * It sets the `isAuthorized` flag to true, which allows us to set a cookie and send events to financeAds.
     */
    const authorize = useCallback(() => {
        if (isAuthorized) return
        setIsAuthorized(true)
    }, [isAuthorized])

    /**
     * Sends a mutation to our backend with the event name and financeAds id. The backend then sends the event to financeAds.
     */
    const track = useCallback<FinanceadsTrackingFn>(
        async (environment, eventName, serverFinanceadsId) => {
            if (!isAuthorized) return
            // eventName is required
            if (!eventName) return

            // prefer the serverFinanceadsId over the client one.
            // serverFinanceadsId is saved in the backend when the user signs up,
            // i.e.this mutation is called for the first time for that user.
            const id = serverFinanceadsId || financeadsId
            if (!id) return

            await trackFinanceadsEventMutation(environment)({
                eventName,
                financeadsId: id,
            })
        },
        [isAuthorized, financeadsId],
    )

    return { authorize, track, financeadsId, isAuthorized }
}

export const [FinanceadsProvider, useFinanceadsContext] = constate(useFinanceadsTracking)
